.common-styles {
position: absolute;
width: 100%;
height: 100%;
background-size: cover;
background-position: center;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/FiraSans-Regular.ttf') format('truetype');
}
@font-face {
font-family: 'Skranji';
src: url('../fonts/Skranji-Regular.ttf') format('truetype'); 
}
