.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: #191919;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .preloader img {
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    }
    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }