body {
font-family: 'Fira Sans', sans-serif;
background-color: #191919;
}
.story {
padding-top: 80px;
padding-bottom: 40px;
margin-left: auto;
margin-right: auto;
transition: opacity 1s 2s;
}
.story.not {
opacity: 0;
}
.story h1 {
font-family: 'Skranji';
  font-size: 48px;
}
.story h2 {
  font-size: 22px;
  font-style: italic;
}
.story h1,
.story h2,
.story h4,
.story p {
position: relative;
z-index: 2;
width: 80%;
max-width: 800px;
margin-left: auto;
margin-right: auto;
color: white;
}
.story-hidden {
transition: all 0.6s;
}
.story-hidden.not {
  opacity: 0;
  transform: translateY(20px);
}

.image-wrapper {
  padding-bottom: 20px;
  padding-top: 20px;
}

.story img {
  display: block;
  position: relative;
  z-index: 2;
  width: 80%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
}
.story-hidden img {
border-radius: 8px;
}
.h2-spacing {
    margin-top: 40px; /* Adjust this value as needed */
    margin-bottom: 40px; /* Adjust this value as needed */
}
 