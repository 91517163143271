.about {
overflow: auto;
min-height: 100vh;
background-color: #e2ede8
}
.about-content {
position: relative;
margin-top: 75px;
margin-left: auto;
margin-right: auto;
display: flex;
width: 100%;
padding-left: 20px;
padding-right: 20px;
color: rgb(0, 0, 0);
font-family: Fira Sans;
}
.about-header {
margin-left: auto;
margin-right: auto;
min-width: 400px;
padding-right: 20px;
}
.heading {
font-weight: bolder;
margin-bottom: 20px;
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-size: 40px;
}
.heading-name {
margin-bottom: 20px;
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-size: 36px;
}
.main-name {
font-family: Skranji;
}
.type-container {
font-size: 20px;
margin-bottom: 20px;
}
.about-texts {
margin: auto;
max-width: 800px;
margin-bottom: 20px;
}
.gallery-link,
.about-link {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
font-size: 18px;
}
.about-texts h1 {
font-size: 28px;
font-weight: bold;
color: rgb(0, 0, 0);
font-family: Fira Sans;
}
.about-texts h2 {
font-size: 28px;
font-weight: bold;
color: rgb(0, 0, 0);
font-family: Fira Sans;
}
.about-texts p {
font-size: 16px;
line-height: 1.5;
}
@media screen and (max-width: 767px) {
.about-texts {
margin-top: 20px;
margin-bottom: 20px;
}
.about-content {
flex-direction: column;
align-items: center;
}
}
.header-image {
position: relative;
}
.header-image img {
margin-top: 20px;
width: 100%;
max-width: 512px;
height: auto;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
border-radius: 15px;
}
.substack-image {
position: relative;
}
.substack-image img {
margin-bottom: 20px;
width: 100%;
height: 200px;
overflow: hidden;
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
border-radius: 15px;
object-fit: cover;
}
.element-image {
position: relative;
}
.element-image img {
margin-bottom: 20px;
width: 75%;
/* height: 200px; */
overflow: hidden;
/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
border-radius: 15px;
object-fit: cover;
}
.nft-id {
margin-top: 10px;
font-size: 20px;
text-align: center;
}
.fade-in {
animation: fadeIn ease 1s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}