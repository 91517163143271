.rain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 25%;
    }
    .rain .drop {
    background: linear-gradient(to bottom, rgba(13,52,58,1) 0%, rgba(255,255,255,0.6) 50%, white 100%);
    width: 0.5px;
    height: 50px;
    position: absolute;
    bottom: 200px;
    animation: fall .63s linear infinite;
    transform: rotate(350deg);
    }
    @keyframes fall {
    to { margin-top: 900px; }
    }