.home {
overflow: auto;
min-height: 100vh;
background-color: #191919;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
text-align: center;
color: rgb(255, 255, 255);
font-family: Fira Sans;
font-weight: bold;
}
.home img {
max-width: 100%;
height: auto;
margin-bottom: 20px;
}
.wave {
animation-name: wave-animation;
animation-duration: 2.1s;
animation-iteration-count: infinite;
transform-origin: 70% 70%;
display: inline-block;
}
@keyframes wave-animation {
0% {
transform: rotate(0deg);
}
10% {
transform: rotate(14deg);
}
20% {
transform: rotate(-8deg);
}
30% {
transform: rotate(14deg);
}
40% {
transform: rotate(-4deg);
}
50% {
transform: rotate(10deg);
}
60% {
transform: rotate(0deg);
}
100% {
transform: rotate(0deg);
}
}
.link-to-gallery {
position: absolute;
z-index: 2;
/* border: 1px solid red; */
top: 29%;
left: 42%;
width: 3%;
height: 15%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-about {
position: absolute;
z-index: 2;
top: 10%;
left: 82%;
width: 8%;
height: 30%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-lore {
position: absolute;
z-index: 2;
top: 25%;
left: 5%;
width: 10%;
height: 15%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-substack {
position: absolute;
z-index: 2;
top: 3%;
left: 63%;
width: 6%;
height: 12%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-discord {
position: absolute;
z-index: 2;
top: 22%;
left: 76%;
width: 4%;
height: 15%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-twitter {
position: absolute;
z-index: 2;
top: 44%;
left: 84%;
width: 7%;
height: 14%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-buy {
/* border: 1px solid red; */
position: absolute;
z-index: 2;
top: 28%;
left: 68%;
width: 8%;
height: 14%;
cursor: pointer;
transition: opacity 0.3s ease;
}
.link-to-gallery::after,
.link-to-lore::after,
.link-to-about::after,
.link-to-substack::after,
.link-to-twitter::after,
.link-to-buy::after,
.link-to-discord::after {
content: "";
display: block;
position: absolute;
top: -10%;
left: -10%;
width: 120%;
height: 120%;
background: radial-gradient(circle at center, rgba(255, 255, 255, 0.3) 0%, transparent 40%);
opacity: 0;
transition: opacity 0.3s ease;
}
.link-to-gallery:hover::after,
.link-to-lore:hover::after,
.link-to-about:hover::after,
.link-to-substack:hover::after,
.link-to-twitter:hover::after,
.link-to-buy:hover::after,
.link-to-discord:hover::after {
opacity: 1;
}
@media (max-width: 767px) {
.link-to-gallery {
top: 32%;
left: 4%;
width: 14%;
height: 12%;
}
.link-to-twitter {
top: 46%;
left: 78%;
width: 14%;
height: 7%;
}
.link-to-discord {
top: 38%;
left: 14%;
width: 12%;
height: 6%;
}
.link-to-substack {
top: 7%;
left: 80%;
width: 14%;
height: 12%;
}
.link-to-about {
display: none;
}
.link-to-lore {
display: none;
}
}