@keyframes slideUp {
from {
transform: translateY(100%);
}
to {
transform: translateY(0);
}
}
footer {
font-family: Fira Sans;
padding: 20px;
background-color: rgba(212, 212, 212, 0.36);
color: rgb(0, 0, 0);
display: flex;
justify-content: space-between;
align-items: center;
position: fixed;
border-width: 0px;
border-radius: 15px;
text-decoration: none;
font-size: 24px;
font-weight: bold;
bottom: 10px;
right: 10px;
transform: translateY(0);
transition: transform 1s;
animation: slideUp 6s forwards;
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
}
.discord-logo {
width: 40px;
height: 40px;
margin-left: 10px;
}