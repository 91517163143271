.gallery {
overflow: auto;
min-height: 100vh;
background-color: #e2ede8;
}
.gallery-header-2 {
position: fixed;
background-color: #e2ede8;
margin-top: 60px;
align-items: center;
gap: 20px;
z-index: 5;
display: flex;
flex-direction: column;
align-items: center;
height: 120px;
width: 100%;
box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.connectivity-status {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
font-size: 24px;
font-weight: 500;
border-radius: 8px;
z-index: 5;
padding-top: 10px;
}
.header-content {
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
width: 100%;
}
.loading {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
background-color: #e2ede8;
}
.loading img {
width: 50px;
height: 50px;
animation: spin 2s linear infinite;
}
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.wallet-btn {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
background-color: rgba(212, 212, 212, 0.36);
border-radius: 5px;
padding: 5px 8px;
cursor: pointer;
z-index: 5;
}
.wallet-btn:hover {
opacity: 0.5;
transition: 0.3s;
}
.gallery-icon {
position: relative;
z-index: 5;
}
.gallery-icon img{
height: 24px;
width: 24px;
}
.gallery-text {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
font-size: 24px;
font-weight: 500;
border-radius: 8px;
z-index: 5;
}
.search-box {
padding: 10px;
border-radius: 4px;
border: 1px solid #000;
font-size: 12px;
color: #000;
width: 100px;
background-color: transparent;
font-weight: bold;
z-index: 5; 
}
.search-box:focus {
outline: none;
border-color: #000;
}
.galleryobject-gallery-card:hover .galleryobject-image {
transform: scale(1.05);
}
.galleryobject-gallery-card.enlarged:hover .galleryobject-image {
transform: scale(1);
}
.galleryobject-gallery-card:hover .galleryobject-heading {
z-index: 1;
position: relative;
color: rgb(0, 0, 0);
font-size: 20px;
}
.galleryobject-gallery-card:hover .galleryobject-heading,
.galleryobject-gallery-card:hover .ElementMarketLink {
display: none;
}
.galleryobject-gallery-card.enlarged .galleryobject-heading {
font-size: 20px;
}
.galleryobject-gallery-card.enlarged .galleryobject-container,
.galleryobject-gallery-card.enlarged:hover .galleryobject-container {
opacity: 1;
}
.galleryobject-gallery-card.enlarged .galleryobject-heading,
.galleryobject-gallery-card.enlarged .ElementMarketLink {
display: block;
}
.ElementMarketLink {
font-family: Fira Sans;
font-weight: bold;
color: rgb(0, 0, 0);
font-size: 20px;
text-decoration: none;
}
.galleryobject-gallery-card {
width: 200px;
height: 200px;
transition: transform 0.3s;
}
.galleryobject-gallery-card.enlarged {
width: 512px;
height: 512px;
z-index: 4;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
.galleryobject-gallery-card.enlarged {
width: 412px;
height: 412px;
}
}
.galleryobject-gallery-card.enlarged:hover,
.galleryobject-gallery-card.enlarged:hover img {
filter: none;
}
.galleryobject-gallery-card.enlarged .galleryobject-container {
opacity: 1;
position: absolute;
bottom: 0;
left: 50%;
transform: translate(-50%, 0);
width: 100%;
}
.galleryobject-gallery-card.enlarged .galleryobject-container {
background-color: rgba(212, 212, 212, 0.36);
text-align: center;
padding: 10px 0;
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
border-radius: 8px;
}
.galleryobject-gallery-card.enlarged .galleryobject-id {
display: none;
}
.galleryobject-gallery-card.enlarged .galleryobject-heading:hover,
.galleryobject-gallery-card.enlarged a:hover {
transform: scale(1);
}
.galleryobject-gallery-card.enlarged:hover .galleryobject-heading,
.galleryobject-gallery-card.enlarged:hover .ElementMarketLink {
display: block;
}
.galleryobject-gallery-card.enlarged:hover .galleryobject-container, 
.galleryobject-gallery-card.enlarged .galleryobject-container {
opacity: 1;
}
#galleryGrid {
margin-top: 200px;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}
.galleryobject-gallery-card {
display: flex;
position: relative;
align-items: flex-start;
flex-direction: column;
justify-content: center;
}
.galleryobject-image {
top: 0px;
left: 0px;
right: auto;
width: 100%;
bottom: auto;
height: 100%;
position: absolute;
box-shadow: 2px 2px 10px 0px #969696;
object-fit: cover;
border-radius: 8px;
}
.galleryobject-container {
display: flex;
opacity: 0;
padding: 16px;
z-index: 1;
transition: 0.3s;
align-items: center;
flex-direction: column;
justify-content: center;
background-image: rgba(212, 212, 212, 0.36);
}
.galleryobject-container:hover {
opacity: 1;
}
.galleryobject-heading {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
width: 100%;
height: 100%;
font-size: 1.5rem;
align-self: flex-start;
font-weight: bold;
}
.gallery-grid{
max-width: 1200px;
margin: 0 auto;
display: flex;
align-self: center;
align-items: flex-start;
padding-top: 32px;
padding-left: 48px;
padding-right: 48px;
flex-direction: row;
padding-bottom: 32px;
justify-content: center;
}
.gallery-cards {
flex: 1;
display: grid;
grid-gap: 16px;
grid-row-gap: 36px;
align-self: center;
justify-content: center;
grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
grid-auto-flow: dense;
}
@media only screen and (max-width: 767px) {
.gallery-grid{
padding-left: 28px;
padding-right: 28px;
}
}
.blur-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 4;
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
display: none;
}
.blur-overlay.show {
display: block;
}
.galleryobject {
position: relative;
}
.galleryobject-id {
position: absolute;
left: 50%;
bottom: -26px;
transform: translateX(-50%);
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
}
.close-button {
position: absolute;
top: 10px;
right: 10px;
cursor: pointer;
z-index: 2;
}
.close-button img {
width: 20px;
height: 20px;
}
.gallery-cards.isSingleImage {
width: 200px;
height: 200px;
}