header {
position: absolute;
left: 0;
right: 0;
padding: 20px 20px 10px;
display: flex;
justify-content: space-between;
align-items: flex-start;
z-index: 7;
transition: background-color 0.5s ease;
}
header.scroll-active {
position: fixed;
top: 0;
}
.about-header.scroll-active {
background-color: #e2ede8;
box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.lore-header.scroll-active {
background-color: #191919;
box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.story-header.scroll-active {
background-color: #191919;
box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}
.gallery-header.scroll-active {
background-color: #e2ede8;
transition: none;
}
.default-header.scroll-active {
background-color: transparent;
}
/* img,
svg {
vertical-align: baseline;
} */
#brand {
display: flex;
align-items: center;
}
#brand a img {
width: 120px;
height: auto;
padding: 3px;
border: 3px solid #e20000;
border-radius: 8px;
position: relative;
z-index: 999;
}
ul {
list-style: none;
display: flex;
align-items: center;
justify-content: space-around;
}
ul li a {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
}
ul li {
margin-left: 10px;
background-color: rgba(212, 212, 212, 0.36);
border-radius: 5px;
padding: 4px 8px;
}
.social-icon {
width: 20px;
height: 20px;
vertical-align: middle;
}
.connectivity {
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
text-decoration: none;
cursor: pointer;
}
ul li:hover {
opacity: 0.5;
transition: 0.3s;
}
#mobile-nav-container {
position: relative;
z-index: 3;
}
#hamburger-icon {
position: relative;
margin: auto 0;
display: none;
cursor: pointer;
z-index: 3;
}
#hamburger-icon div {
width: 35px;
height: 3px;
margin: 6px 0;
transition: 0.4s;
}
.change .bar1 {
-webkit-transform: rotate(-45deg) translate(-6px, 6px);
transform: rotate(-45deg) translate(-6px, 6px);
}
.change .bar2 {
opacity: 0;
}
.change .bar3 {
-webkit-transform: rotate(45deg) translate(-6px, -8px);
transform: rotate(45deg) translate(-6px, -8px);
}
.open .mobile-menu {
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
}
.mobile-menu {
display: none;
position: fixed;
top: 75px;
left: 5px;
height: 100vh;
width: 100%;
}
.mobile-menu li {
margin-bottom: 10px;
display: block;
width: max-content;
margin-left: -20px;
}
@media only screen and (max-width: 767px) {
header nav {
display: none;
}
#hamburger-icon {
display: block;
}
footer {
left: 10px;
}
ul li a {
font-size: 24px;
}
.connectivity {
font-size: 24px;
}
}
body::after {
content: '';
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(212, 212, 212, 0.36);
backdrop-filter: blur(10px);
-webkit-backdrop-filter: blur(10px);
display: none;
z-index: 6;
}
body.menu-active::after {
display: block;
overflow: hidden;
}
.mobile-menu .info {
display: block;
color: rgb(0, 0, 0);
font-family: Fira Sans;
font-weight: bold;
font-size: 16px;
text-align: left;
background-color: transparent;
border-radius: 0px;
}
.mobile-menu .info span {
display: block;
font-size: 16px;
margin-left: -20px;
}
#mobile-nav-container {
display: flex;
align-items: center;
justify-content: space-between;
}
.mobile-buttons-container {
display: flex;
align-items: center;
}
.hamburger-mute-container {
display: flex;
gap: 30px ;
align-items: center;
justify-content: space-between;
}
.mobile-mute-button {
display: none;
}
.mobile-mute-button, 
.desktop-mute-button {
height: 32px;
width: 32px;
}
@media screen and (max-width: 767px) {
.mobile-mute-button {
display: block;
}
}
.header-image-2 {
position: relative;
top: -30px;
}
.mobile-menu img {
max-width: 512px;
}
.mute-button {
position: absolute;
bottom: 10px;
left: 10px;
cursor: pointer;
width: 50px;
height: 50px;
z-index: 2;
}
